import React, { Component } from 'react';
import Header from './components/header';
import Marketing from './components/containerMarketing';
import Features from './components/features';
import Carousel from './components/carousel';

class App extends Component {

  state = {
    pupUp : true
  }

  
  render() {
    return (
      <div>
        <Header title='Title Here'></Header>
        <main role='main'>
          <Carousel></Carousel>
          <Marketing popUp={this.state.pupUp}></Marketing>
          <Features></Features>
        </main>
      </div>
    );
  }
}

export default App;
