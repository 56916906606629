import React from 'react';

const Features = (props) => {
    return (
        <div className="row mt-5 text-center features">
                <div className='col-12 mx-auto'>
                    <p>
                        Playdoit es una marca nacional de juegos en línea registrada en México.<br/>
                        PERMISO SEGOB DGAJS/SCEVF/P-02/2005
                    </p>    
                </div>
                <div className="col-12 mx-auto">
                   
                </div>
            </div>
    )
}

export default Features;