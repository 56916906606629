import React, {Component} from 'react';

class Header extends Component {

    sender = (e) => {

        e.preventDefault();

    }

    render () {

        const logo = 'https://www.playdoit.mx/cms/img/logo.png';

        return (
            <header>
                <nav className='navbar navbar-expand-md navbar-dark fixed-top subheader-fluid'>
                    <a className="navbar-brand logo-playdoit" href="https://www.playdoit.mx/"><img src={logo}  className='header__logo' alt='Sitio Playdoit Oficial'/></a>
                    <button className='navbar-toggler' type='button' data-toggle='collapse' data-target='#navbarCollapse' aria-controls='covbarCollapse' aria-expanded='false' aria-label='Togglenavigation'>
                        <span className='navbar-toggler-icon'></span>   
                    </button>
                    <div className='collapse navbar-collapse ' id='navbarCollapse'>
                        <ul className='navbar-nav mx-auto'>
                            <li className='nav-item active'><a className='nav-link nav-size' href='https://www.playdoit.mx/es/deportes/demo'>Deportes<span className='sr-only'></span></a></li>
                            <li className='nav-item active'><a className='nav-link nav-size' href='https://www.playdoit.mx/es/apuestasenvivo/demo'>En Vivo</a></li>
                            <li className='nav-item active'><a className='nav-link nav-size' href='https://www.playdoit.mx/es/virtual/demo/VirtualFootballLeague'>Virtuales</a></li>
                            <li className='nav-item active'><a className='nav-link nav-size' href='https://www.playdoit.mx/es/gamegroup/casino/Acumuladas'>Casino en Vivo</a></li>
                            <li className='nav-item active'><a className='nav-link nav-size' href='https://www.playdoit.mx/es/gamegroup/casino/Acumuladas'>Casino</a></li>
                        </ul>
                        <form className='form-inline mt-2 mt-md-0 mr-auto' onSubmit={this.sender}>
                            <button className='btn my-2 my-sm-0 button-enter'>Entrar <i className="fas fa-angle-down"></i></button>
                            <button className='btn my-2 my-sm-0 button-register'>¡Registrate! &nbsp;<i className="fas fa-user"></i></button>
                        </form>
                    </div>
                </nav>
            </header>
        )
    }
}


export default Header;

