import React, {Component, Fragment} from 'react';
import {isMobile} from 'react-device-detect';

class Carousel extends Component {

    slider1 = 'https://www.playdoit.mx/cms/img/slider-img/desktop-banner-1.webp';
    slider2 = 'https://www.playdoit.mx/cms/img/slider-img/desktop-banner-2.webp';
    slider3 = 'https://www.playdoit.mx/cms/img/slider-img/desktop-banner-3.webp';
    slider3 = 'https://www.playdoit.mx/cms/img/slider-img/desktop-banner-4.webp';

    slideMovil = () => {
        if(!isMobile){
            return(
                <Fragment>
                    <div id="myCarousel" className="carousel slide" data-ride="carousel">
                        <ol className="carousel-indicators">
                            <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
                            <li data-target="#myCarousel" data-slide-to="1"></li>
                            <li data-target="#myCarousel" data-slide-to="2"></li>
                        </ol>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img className='img-slide img-fluid' src='https://www.playdoit.mx/cms/img/slider-img/desktop-banner-1.webp' alt='Slider Playdoit' />
                            </div>
                            <div className="carousel-item">
                                <img className='img-slide img-fluid' src='https://www.playdoit.mx/cms/img/slider-img/desktop-banner-2.webp' alt='Slider Playdoit' />
                            </div>
                            <div className="carousel-item">
                                <img className='img-slide img-fluid' src='https://www.playdoit.mx/cms/img/slider-img/desktop-banner-3.webp' alt='Slider Playdoit' />
                            </div>
                            <div className="carousel-item">
                                <img className='img-slide img-fluid' src='https://www.playdoit.mx/cms/img/slider-img/desktop-banner-4.webp' alt='Slider Playdoit' />
                            </div>
                        </div>
                        <a className="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </a>
                    </div>
                </Fragment>
            )
        }else{
            return (
                <Fragment>
                    <div id="myCarousel" className="carousel slide" data-ride="carousel">
                        <ol className="carousel-indicators">
                            <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
                            <li data-target="#myCarousel" data-slide-to="1"></li>
                            <li data-target="#myCarousel" data-slide-to="2"></li>
                        </ol>
                        <div className="carousel-inner">
                            <div className="carousel-item active carousel-itemM">
                                <img className='img-slide img-fluid img-slideM' src='https://www.playdoit.mx/cms/img/slider-img/desktop-banner-1.webp' alt='Slider Playdoit' />
                            </div>
                            <div className="carousel-item carousel-itemM">
                                <img className='img-slide img-fluid img-slideM' src='https://www.playdoit.mx/cms/img/slider-img/desktop-banner-2.webp' alt='Slider Playdoit' />
                            </div>
                            <div className="carousel-item carousel-itemM">
                                <img className='img-slide img-fluid img-slideM' src='https://www.playdoit.mx/cms/img/slider-img/desktop-banner-3.webp' alt='Slider Playdoit' />
                            </div>
                            <div className="carousel-item carousel-itemM">
                                <img className='img-slide img-fluid img-slideM' src='https://www.playdoit.mx/cms/img/slider-img/desktop-banner-4.webp' alt='Slider Playdoit' />
                            </div>
                        </div>
                        <a className="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </a>
                    </div>
                </Fragment>
            )
        }
    }

    render () {
        return this.slideMovil();
        
    }
}

export default Carousel;